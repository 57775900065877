import React, { useEffect, useState } from 'react';
import './css/Users_chatHistory.css';
import instance from '../Utils/api';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


const Users_chatHistory = () => {
    const [data, setData] = useState();
    const { id } = useParams();
    const navigate = useNavigate();


    const fetchData = async () => {
        try {
            const response = await instance.get(`/getUsersChatData/${id}`);
            setData(response.data[0]);
        } catch (error) {
            console.error("Error fetching chat data:", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const messageList = data?.messages || [];

    // Function to parse JSON strings safely
    const parseJSON = (str) => {
        try {
            return JSON.parse(str);
        } catch (error) {
            console.error("Failed to parse JSON string:", error);
            return {};
        }
    };

    // Convert timestamp to a readable date and time format
    const convertTimestamp = (timestamp) => {
        const date = new Date(timestamp * 1000); // Convert to milliseconds
        return date.toLocaleString(); // Format the date as a readable string
    };

    return (
        <>
            <Button sx={{ backgroundColor: '#009999', color: 'white', gap: '10px', marginBottom: '10px' }} onClick={() => navigate(-1)} ><ArrowBackIcon />Back</Button>

            <div className="chat-container">

                {messageList.length ? messageList.map((message, index) => {
                    const parsedMessage = parseJSON(message.Message);
                    const parsedReplay = parseJSON(message.replay);
                    const messagetype = parsedMessage[parsedMessage.type] || {};
                    let messagedata;

                    if (messagetype?.body) {
                        messagedata = messagetype.body;
                    } else if (messagetype?.type === "button_reply") {
                        messagedata = messagetype.button_reply.title;
                    } else if (messagetype?.type === "list_reply") {
                        messagedata = messagetype.list_reply.title;
                    } else if (messagetype?.type === "nfm_reply") {
                        messagedata = messagetype.nfm_reply.name + messagetype.nfm_reply.body;
                    } else {
                        messagedata = messagetype.text?.body || "No message text";
                    }

                    const isUserMessage = message.sender === 'user'; // Check if the message is from the user or other party

                    return (
                        <div key={index} className={`message-container ${isUserMessage ? 'user' : 'other'}`}>
                            <div className={`message-bubble ${isUserMessage ? 'user-message' : 'other-message'}`}>
                                <div className="message-text">
                                    {messagedata}
                                </div>
                                <div className="message-time">
                                    {parsedMessage.timestamp ? convertTimestamp(parsedMessage.timestamp) : "No timestamp"}
                                </div>
                            </div>

                            {/* If there's a reply, show it below the original message */}
                            {parsedReplay.message ? (
                                <div className={`reply-bubble ${isUserMessage ? 'user-reply' : 'other-reply'}`}>
                                    <div className="reply-message">
                                        <strong>Reply:</strong> {parsedReplay?.message ? parsedReplay.message?.text || "No reply text" : parsedReplay?.template_name}
                                    </div>
                                    {parsedReplay.message?.buttons && (
                                        <div className="buttons">
                                            {parsedReplay.message?.buttons.map((button, idx) => (
                                                <button key={idx} className="replay-button">
                                                    {button.text}
                                                </button>
                                            ))}
                                        </div>
                                    )}
                                    <div className="message-time">
                                        {parsedMessage.timestamp ? convertTimestamp(parsedMessage.timestamp) : "No timestamp"}
                                    </div>
                                </div>
                            ) :
                                <div className={`reply-bubble ${isUserMessage ? 'user-reply' : 'other-reply'}`}>
                                    <div className="reply-message">
                                        <strong>Reply:</strong> {parsedReplay?.message ? parsedReplay.message?.text || "No reply text" : parsedReplay?.template_name+" Template Sent"}
                                    </div>
                                    {parsedReplay.message?.buttons && (
                                        <div className="buttons">
                                            {parsedReplay.message?.buttons.map((button, idx) => (
                                                <button key={idx} className="replay-button">
                                                    {button.text}
                                                </button>
                                            ))}
                                        </div>
                                    )}
                                    <div className="message-time">
                                        {parsedMessage.timestamp ? convertTimestamp(parsedMessage.timestamp) : "No timestamp"}
                                    </div>
                                </div>}
                        </div>
                    );
                }) : "No Chat History Available"}
            </div>
        </>
    );
}

export default Users_chatHistory;
