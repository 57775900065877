import React from 'react';
import { Link, Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import './styles/Home.css';
import Topbar from './Topbar';
import LogoutIcon from '@mui/icons-material/Logout';
import logo from '../Assets/logo.svg.png'
import { Stack } from '@mui/material';

const Home = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const isActive = (path) => location.pathname.includes(path);
    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/login');
        window.location.reload();
    }
    return (
        <React.Fragment>
            {/* <Topbar /> */}
            <aside className='asideNavbar' >
                <div className='asideNavbarDiv'>
                    {/* <Link id='link' to='/' >
                        <h1>FNB Connect</h1></Link> */}
                    <Link id='link' to='/' >
                        <Stack sx={{alignItems:'center',marginBottom:'20px',marginTop:'20px'}}>
                            <img src={logo} alt='LOGO' width={'200px'} />
                        </Stack>
                    </Link>

                    <Link to="/Dashboard" id='link'>
                        <div
                            className={`menu ${isActive("/Dashboard") ? "activeRoute" : ""
                                }`}
                        >
                            Dashboard
                        </div>
                    </Link>
                    <Link to="/UsersList" id='link'>
                        <div
                            className={`menu ${isActive("/UsersList") ? "activeRoute" : ""
                                }`}
                        >
                            Users List
                        </div>
                    </Link>
                    <Link to="/LeadsList" id='link'>
                        <div
                            className={`menu ${isActive("/LeadsList") ? "activeRoute" : ""
                                }`}
                        >
                            Leads List
                        </div>
                    </Link>
                    <Link to="/AgentsList" id='link'>
                        <div
                            className={`menu ${isActive("/AgentsList") ? "activeRoute" : ""
                                }`}
                        >
                            Agents List
                        </div>
                    </Link>
                    <Link to="/Templates" id='link'>
                        <div
                            className={`menu ${isActive("/Templates") ? "activeRoute" : ""
                                }`}
                        >
                            Templates
                        </div>
                    </Link>
                    <Link to="/Messages" id='link'>
                        <div
                            className={`menu ${isActive("/Messages") ? "activeRoute" : ""
                                }`}
                        >
                            Messages
                        </div>
                    </Link>
                    <Link to="/RechargeData" id='link'>
                        <div
                            className={`menu ${isActive("/RechargeData") ? "activeRoute" : ""
                                }`}
                        >
                            User Recharge Log
                        </div>
                    </Link>
                    

                    <div className={`logout`} onClick={handleLogout}>
                        <LogoutIcon />Logout
                    </div>
                </div>
            </aside>
            <div className='outline_style' style={{ position: 'absolute', top: '100px', left: '18%', width: '78%' }}>
                <Outlet /></div>
        </React.Fragment>
    )
}

export default Home
