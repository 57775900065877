import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import instance from '../Utils/api';
import Pagination from '../components/Pagination';
import SearchBar from '../components/SearchBar';
import { Button, Stack } from '@mui/material';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#009999",
        color: theme.palette.common.white,
        fontSize: '16px',
        fontWeight: 600
        // fontFamily: "Poppins",

    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        // fontFamily: "Poppins",
        color: "#828282"
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,

    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const Authors_List = () => {
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [filteredData, setFilteredData] = useState(data);


    const rowsPerPage = 10;
    const dataToShow = filteredData.slice((page - 1) * rowsPerPage, page * rowsPerPage);
    console.log(dataToShow);
    const fetchData = async () => {
        try {
            const response = await instance.get("/getLeadsdata");
            // console.log(response.data);
            setData(response.data)
            setFilteredData(response.data)
        } catch (error) {
            console.log(error);

        }
    }
    const postData = async () => {
        try {
            // Make the request with responseType set in the Axios config
            const response = await instance.post("/getLeadsdatadownload", null, {
                responseType: 'blob', // Set response type to blob for downloading files
            });
    
            // Create a URL for the blob response
            const url = window.URL.createObjectURL(new Blob([response.data]));
    
            // Create a link element
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'leads_data.xlsx'); // Set the name for the downloaded file
    
            // Append the link to the body (required for Firefox)
            document.body.appendChild(link);
    
            // Programmatically click the link to trigger the download
            link.click();
    
            // Cleanup: remove the link from the document
            document.body.removeChild(link);
    
            // Optionally, revoke the object URL after the download
            window.URL.revokeObjectURL(url);
    
        } catch (error) {
            console.error("Error downloading the file:", error);
        }
    };
    

    useEffect(() => {
        fetchData();
    }, [])
    return (
        <div>
            <div style={{ width: "100%", padding: "15px 0px 15px 0px", display: 'flex', justifyContent: 'space-between' }}>
                <SearchBar data={data} setFilteredData={setFilteredData} filterKey={'USER_NAME'} />
                <Stack direction="row" spacing={2}>
                    <Pagination page={page} setPage={setPage} count={filteredData.length} rowsPerPage={rowsPerPage} />
                    <Button sx={{ backgroundColor: '#009999', color: 'white' }} onClick={postData}>Download</Button>
                </Stack>
            </div>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 600 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>ID</StyledTableCell>
                            <StyledTableCell>User Name</StyledTableCell>
                            <StyledTableCell align="center">Gender</StyledTableCell>
                            <StyledTableCell align="center">Email</StyledTableCell>
                            <StyledTableCell align="center">Phone Number</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dataToShow.map((row) => (
                            <StyledTableRow key={row?.name}>
                                <StyledTableCell component="th" scope="row">
                                    {row?.ID}

                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row">{row?.USER_NAME}</StyledTableCell>
                                <StyledTableCell align="center">{row?.GENDER}</StyledTableCell>
                                <StyledTableCell align="center">{row?.EMAIL}</StyledTableCell>
                                <StyledTableCell align="center">{row?.COUNTRY_CODE} {row.PHONE_NUMBER}</StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

export default Authors_List
