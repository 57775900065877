// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.topbar {
    background-color: #ffffff;
    border-radius: 4px;
    display: flex;
    justify-content: flex-start;
    padding: 10px;
    /* height: 40px; */
    width: 79%;
    position: relative;
    left: 18%;

    transition: box-shadow 300ms 0ms;
    border-radius: 4px;
    margin: 20px 0px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
}

.topbar h2 {
    margin: 0px;
    color: #828282;
    font-size: 25px;
    text-transform: uppercase;
}`, "",{"version":3,"sources":["webpack://./src/components/styles/Topbar.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,aAAa;IACb,2BAA2B;IAC3B,aAAa;IACb,kBAAkB;IAClB,UAAU;IACV,kBAAkB;IAClB,SAAS;;IAET,gCAAgC;IAChC,kBAAkB;IAClB,gBAAgB;IAChB,yHAAyH;AAC7H;;AAEA;IACI,WAAW;IACX,cAAc;IACd,eAAe;IACf,yBAAyB;AAC7B","sourcesContent":[".topbar {\n    background-color: #ffffff;\n    border-radius: 4px;\n    display: flex;\n    justify-content: flex-start;\n    padding: 10px;\n    /* height: 40px; */\n    width: 79%;\n    position: relative;\n    left: 18%;\n\n    transition: box-shadow 300ms 0ms;\n    border-radius: 4px;\n    margin: 20px 0px;\n    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;\n}\n\n.topbar h2 {\n    margin: 0px;\n    color: #828282;\n    font-size: 25px;\n    text-transform: uppercase;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
