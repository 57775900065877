
import './App.css';
// import RazerpayIntegration from './components/RazerpayIntegration';
import { Navigate, Route, Router, Routes, useNavigate } from 'react-router-dom';
// import Payment_status from './components/Payment_status';
import Feedback from './pages/Feedback';
import Authors_List from './pages/Authors_List';
import Dashboard from './pages/Dashboard';
import Bookings from './pages/Bookings';
import Home from './components/Home';
import Login from './pages/Login';
import { useState } from 'react';
import Messages from './pages/Messages';
import Agents_List from './pages/Agents_List';
import Chathistory from './pages/chatHistory';
import RechargeData from './pages/Recharge';
import Users_chatHistory from './pages/Users_chatHistory';

function App() {
  const [triggerRefresh, setTriggerRefresh] = useState();
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  console.log(token);

  let isAuth = token !== null;
  console.log(isAuth);


  return (
    <Routes>
      {isAuth === true ?
        <Route path='/' element={<Home />}>
          <Route index element={<Dashboard />} />
          <Route path='/Dashboard' element={<Dashboard />} />
          <Route path='/UsersList' element={<Feedback />} />
          <Route path='/UsersList/:id' element={<Users_chatHistory />} />
          <Route path='/LeadsList' element={<Authors_List />} />
          <Route path='/AgentsList' element={<Agents_List />} />
          <Route path='/AgentsList/:id' element={<Chathistory />} />
          <Route path='/RechargeData' element={<RechargeData />} />
          <Route path='/*' element={<Navigate to={"/Dashboard"} />} />
          <Route path='/Templates' element={<Bookings />} />
          <Route path='/Messages' element={<Messages />} />
        </Route> :
        <Route path='/login' element={<Login setTriggerRefresh={setTriggerRefresh} />} />}
      <Route path='/*' element={<Navigate to={"/login"} />} />

    </Routes>
  );
}

export default App;
