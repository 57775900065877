// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ` 
._4tet {
    display: flex;
    align-items: center;
    width: 180px;
    border-radius: 6px;
    gap: 4px;
    background-color: rgba(198, 198, 198, 0.2);
    padding: 4px 12px 4px 4px;
    border: 1px solid #c6c6c6;
  }
  ._4tet .refold-pat {
    border: transparent;
    width: 200px;
    color: #5c6878;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    background: rgba(198, 198, 198, 0);
  }
  
  ._4tet .refold-pat:focus-visible {
    outline: none;
  }`, "",{"version":3,"sources":["webpack://./src/components/styles/SearchBar.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,mBAAmB;IACnB,YAAY;IACZ,kBAAkB;IAClB,QAAQ;IACR,0CAA0C;IAC1C,yBAAyB;IACzB,yBAAyB;EAC3B;EACA;IACE,mBAAmB;IACnB,YAAY;IACZ,cAAc;IACd,gCAAgC;IAChC,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,gBAAgB;IAChB,kCAAkC;EACpC;;EAEA;IACE,aAAa;EACf","sourcesContent":[" \n._4tet {\n    display: flex;\n    align-items: center;\n    width: 180px;\n    border-radius: 6px;\n    gap: 4px;\n    background-color: rgba(198, 198, 198, 0.2);\n    padding: 4px 12px 4px 4px;\n    border: 1px solid #c6c6c6;\n  }\n  ._4tet .refold-pat {\n    border: transparent;\n    width: 200px;\n    color: #5c6878;\n    font-family: \"Inter\", sans-serif;\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 20px;\n    text-align: left;\n    background: rgba(198, 198, 198, 0);\n  }\n  \n  ._4tet .refold-pat:focus-visible {\n    outline: none;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
