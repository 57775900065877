import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import instance from '../Utils/api';
import Pagination from '../components/Pagination';
import SearchBar from '../components/SearchBar';
import { Button, MenuItem, Modal, Select, Stack, TextField, Typography } from '@mui/material';
import { ModalClose, Sheet } from '@mui/joy';
import { toast } from 'react-toastify';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate, useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#009999",
        color: theme.palette.common.white,
        fontSize: '16px',
        fontWeight: 600
        // fontFamily: "Poppins",

    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        // fontFamily: "Poppins",
        color: "#828282"
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,

    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const ChatHistory = () => {
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [filteredData, setFilteredData] = useState(data);
    const navigate = useNavigate();
    const rowsPerPage = 10;
    const dataToShow = filteredData?.slice((page - 1) * rowsPerPage, page * rowsPerPage);
    const fetchData = async () => {
        try {
            const response = await instance.get(`/getRechargeData`);
            console.log(response);
            setData(response.data)
            setFilteredData(response.data)
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchData();
    }, [])

    return (
        <div>
            <div style={{ width: "100%", padding: "15px 0px 15px 0px", display: 'flex', justifyContent: 'space-between' }}>
                <SearchBar data={data} setFilteredData={setFilteredData} filterKey={'user_Id.USER_NAME'} />
                <Stack direction="row" spacing={2}>
                    <Pagination page={page} setPage={setPage} count={filteredData.length} rowsPerPage={rowsPerPage} />
                    {/* <Button sx={{ backgroundColor: '#009999', color: 'white', gap: '10px' }} onClick={() => navigate(-1)} ><ArrowBackIcon />Back</Button> */}
                </Stack>
            </div>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 600 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>User Name</StyledTableCell>
                            <StyledTableCell>Phone Number</StyledTableCell>
                            <StyledTableCell>Validity</StyledTableCell>
                            <StyledTableCell>Bundle</StyledTableCell>
                            <StyledTableCell>Date</StyledTableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dataToShow.length ? dataToShow?.map((row) => (
                            <StyledTableRow key={row?._id}>
                                <StyledTableCell component="th" scope="row">
                                    {row?.user_Id?.USER_NAME}

                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row">{row?.user_Id?.PHONE_NUMBER}</StyledTableCell>
                                <StyledTableCell component="th" scope="row">{row?.Validity}</StyledTableCell>
                                <StyledTableCell component="th" scope="row">{row?.Bundle}</StyledTableCell>
                                <StyledTableCell component="th" scope="row">{new Date(row?.createdAt).toLocaleDateString()}</StyledTableCell>
                            </StyledTableRow>
                        )) : "No data Found"}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

export default ChatHistory
