import { Stack, Typography } from '@mui/material'
import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import instance, { updateToken } from '../Utils/api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import logo from '../Assets/logo.svg.png'


const Login = ({ setTriggerRefresh }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleLogin = async () => {
        if (email === '') {
            return toast.error('Please enter your email');
        }
        if (password === '') {
            return toast.error('Please enter your password');
        }
        try {
            const response = await instance.post('/login', { EMAIL: email, PASSWORD: password });
            console.log(response);
            if (response.data.success === true) {
                toast.success(response.data.message);
                const token = response.data.token;
                localStorage.setItem('token', token);
                updateToken(token);
                setTriggerRefresh(prev => prev + 1);
                navigate('/Dashboard');
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.log(error);
            toast.error(error.response.data.message);
        }
    }
    return (
        <Stack sx={{ width: '100%', height: '100vh', backgroundColor: '#ffffff', alignItems: 'center', justifyContent: 'center' }}>
            <Stack sx={{ width: '40%', backgroundColor: '#f6f6f6', padding: '50px', gap: '20px', borderRadius: '20px', alignItems: 'center', boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.5)' }}>
                <img src={logo} alt='LOGO' width={'200px'} />

                <Typography variant='h5' sx={{ fontSize: '22px', fontWeight: 700, color: '#009999' }}>FNB Connect Whatsapp Chartbot Dashboard</Typography>
                <Stack sx={{ width: '-webkit-fill-available', gap: '20px' }}>
                    <TextField id="outlined-basic" label="Email" variant="outlined" type='email' onChange={(e) => { setEmail(e.target.value) }} />
                    <TextField id="outlined-basic" label="Password" variant="outlined" type='password' onChange={(e) => { setPassword(e.target.value) }} />
                    <Button variant="contained" type='submit' sx={{ backgroundColor: '#009999' }} onClick={handleLogin}>Login</Button>
                </Stack>
            </Stack>
        </Stack>
    )
}

export default Login