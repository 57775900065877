import React from 'react';
import Button from '@mui/material/Button';
import './styles/Topbar.css';

const Topbar = () => {
  return (
    <div className='topbar'>
      {/* <h2>GK Hill View</h2> */}
      {/* <Button variant="contained" style={{backgroundColor:"#079dde"}}  >Logout</Button> */}
    </div>
  )
}

export default Topbar
