import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import instance from '../Utils/api';
import Pagination from '../components/Pagination';
import SearchBar from '../components/SearchBar';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from 'react-router-dom';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#009999",
        color: theme.palette.common.white,
        fontSize: '16px',
        fontWeight: 600,
        fontFamily: "Poppins",

    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        fontFamily: "Poppins",
        color: "#828282"
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const Feedback = () => {
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [filteredData, setFilteredData] = useState(data);
    const navigate = useNavigate();



    const rowsPerPage = 10;
    const dataToShow = filteredData.slice((page - 1) * rowsPerPage, page * rowsPerPage);
    console.log(dataToShow);
    const fetchData = async () => {
        try {
            const response = await instance.get("/getuserdata");
            // console.log(response.data);
            setData(response.data)
            setFilteredData(response.data)
        } catch (error) {
            console.log(error);

        }
    }
    useEffect(() => {
        fetchData();
    }, [])
    return (
        <div>
            <div style={{ width: "100%", padding: "15px 0px 15px 0px", display: 'flex', justifyContent: 'space-between' }}>
                <SearchBar data={data} setFilteredData={setFilteredData} filterKey={'USER_NAME'} />
                <Pagination page={page} setPage={setPage} count={filteredData.length} rowsPerPage={rowsPerPage} />
            </div>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 600 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Phone Number</StyledTableCell>
                            <StyledTableCell align="center">Username</StyledTableCell>
                            <StyledTableCell align="center">Balance</StyledTableCell>
                            <StyledTableCell align="center">Plan</StyledTableCell>
                            <StyledTableCell align="center">Package</StyledTableCell>
                            <StyledTableCell align="center">Chat History</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dataToShow.map((row) => (
                            <StyledTableRow key={row.name}>
                                <StyledTableCell component="th" scope="row">
                                    {/* +{row.PHONE_NUMBER.slice(0,2)} {row.PHONE_NUMBER.slice(2,20)} */}
                                    +{row.PHONE_NUMBER}

                                </StyledTableCell>
                                <StyledTableCell align="center">{row.USER_NAME}</StyledTableCell>
                                <StyledTableCell align="center">{row.PREPAID_BALANCE}</StyledTableCell>
                                <StyledTableCell align="center">{row.PLAN}</StyledTableCell>
                                <StyledTableCell align="center">{row.PACKAGE}</StyledTableCell>
                                <StyledTableCell align="center"><VisibilityIcon sx={{ cursor: 'pointer' }} onClick={() => { navigate(`/UsersList/${row?._id}`) }} /></StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

export default Feedback
