// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.eclipses-jell {
    display: flex;
    align-items: center;
    border-right: 1px solid #d9d9dc;
}

.nuked-cob {
    display: flex;
    align-items: center;
    gap: 20px;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

/* .rewarn-eye {
} */

.docile-tale {
    color: #c6c6c6;
  }

.tusked-porn {
    color: #c6c6c6;
}

/* .mavens-ben {
} */

.nettles-gigs {
    margin-right: 15px;
  }`, "",{"version":3,"sources":["webpack://./src/components/styles/Pagination.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,+BAA+B;AACnC;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,gCAAgC;IAChC,eAAe;IACf,gBAAgB;IAChB,iBAAiB;EACnB;;AAEF;GACG;;AAEH;IACI,cAAc;EAChB;;AAEF;IACI,cAAc;AAClB;;AAEA;GACG;;AAEH;IACI,kBAAkB;EACpB","sourcesContent":[".eclipses-jell {\n    display: flex;\n    align-items: center;\n    border-right: 1px solid #d9d9dc;\n}\n\n.nuked-cob {\n    display: flex;\n    align-items: center;\n    gap: 20px;\n    font-family: \"Inter\", sans-serif;\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 20px;\n  }\n\n/* .rewarn-eye {\n} */\n\n.docile-tale {\n    color: #c6c6c6;\n  }\n\n.tusked-porn {\n    color: #c6c6c6;\n}\n\n/* .mavens-ben {\n} */\n\n.nettles-gigs {\n    margin-right: 15px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
