// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Card{
    width: 250px;
    height: 98px;
    padding: 20px;
    font-family: "Poppins";
    background-color: #009999;
    /* color: #828282; */
    border-radius: 12px;
    cursor: pointer;
    transition: transform .2s;
    display: flex;
    justify-content: center;
    align-items:center;
    flex-direction: column;
    
}
.Card:hover,.Card:hover h2,.Card:hover h3{
    /* background-color: #e6a83c; */
    /* color: #ffffff; */
    transform: scale(1.1);
}

.Card h2{
    margin: 0px 0px 20px 0px;
    color: #ffffff;
    font-size: 20px;

}
.Card h3{
    color: #ffffff;
    margin: 0px;
    font-size: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/styles/Card.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,yBAAyB;IACzB,oBAAoB;IACpB,mBAAmB;IACnB,eAAe;IACf,yBAAyB;IACzB,aAAa;IACb,uBAAuB;IACvB,kBAAkB;IAClB,sBAAsB;;AAE1B;AACA;IACI,+BAA+B;IAC/B,oBAAoB;IACpB,qBAAqB;AACzB;;AAEA;IACI,wBAAwB;IACxB,cAAc;IACd,eAAe;;AAEnB;AACA;IACI,cAAc;IACd,WAAW;IACX,eAAe;AACnB","sourcesContent":[".Card{\n    width: 250px;\n    height: 98px;\n    padding: 20px;\n    font-family: \"Poppins\";\n    background-color: #009999;\n    /* color: #828282; */\n    border-radius: 12px;\n    cursor: pointer;\n    transition: transform .2s;\n    display: flex;\n    justify-content: center;\n    align-items:center;\n    flex-direction: column;\n    \n}\n.Card:hover,.Card:hover h2,.Card:hover h3{\n    /* background-color: #e6a83c; */\n    /* color: #ffffff; */\n    transform: scale(1.1);\n}\n\n.Card h2{\n    margin: 0px 0px 20px 0px;\n    color: #ffffff;\n    font-size: 20px;\n\n}\n.Card h3{\n    color: #ffffff;\n    margin: 0px;\n    font-size: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
