import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import instance from '../Utils/api';
import Pagination from '../components/Pagination';
import SearchBar from '../components/SearchBar';
import { Button, MenuItem, Modal, Select, Stack, TextField, Typography } from '@mui/material';
import { ModalClose, Sheet } from '@mui/joy';
import { toast } from 'react-toastify';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate, useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#009999",
        color: theme.palette.common.white,
        fontSize: '16px',
        fontWeight: 600
        // fontFamily: "Poppins",

    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        // fontFamily: "Poppins",
        color: "#828282"
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,

    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const ChatHistory = () => {
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [page1, setPage1] = useState(1);
    const [filteredData, setFilteredData] = useState(data);
    const [open, setOpen] = useState(false);
    const { id } = useParams();
    const [showData, setShowdata] = useState();
    const [filtershowData, setfilterShowdata] = useState(showData);
    const navigate = useNavigate();

    const rowsPerPage = 10;
    const dataToShow = filteredData?.slice((page - 1) * rowsPerPage, page * rowsPerPage);
    const dataToShow2 = filtershowData?.slice((page1 - 1) * rowsPerPage, page1 * rowsPerPage);
    const fetchData = async () => {
        try {
            const response = await instance.get(`/getchatHisoryData/${id}`);
            console.log(response);
            setData(response.data)
            setFilteredData(response.data)
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchData();
    }, [])

    return (
        <div>
            <div style={{ width: "100%", padding: "15px 0px 15px 0px", display: 'flex', justifyContent: 'space-between' }}>
                <SearchBar data={data} setFilteredData={setFilteredData} filterKey={'agent_Id.full_name'} />
                <Stack direction="row" spacing={2}>
                    <Pagination page={page} setPage={setPage} count={filteredData.length} rowsPerPage={rowsPerPage} />
                    <Button sx={{ backgroundColor: '#009999', color: 'white', gap: '10px' }} onClick={() => navigate(-1)} ><ArrowBackIcon />Back</Button>
                </Stack>
            </div>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 600 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Agent Name</StyledTableCell>
                            <StyledTableCell>Author Number</StyledTableCell>
                            <StyledTableCell align="center">Chat</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dataToShow.length ? dataToShow?.map((row) => (
                            <StyledTableRow key={row?._id}>
                                <StyledTableCell component="th" scope="row">
                                    {row?.agent_Id?.full_name}

                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row">{row?.chat?.author}</StyledTableCell>
                                <StyledTableCell align="center" ><VisibilityIcon sx={{ cursor: 'pointer' }} onClick={() => { setOpen(true); setShowdata(row?.chat?.messages); setfilterShowdata(row?.chat?.messages) }} /></StyledTableCell>
                            </StyledTableRow>
                        )) : "No data Found"}
                    </TableBody>
                </Table>
            </TableContainer>
            <>
                <Modal
                    aria-labelledby="modal-title"
                    aria-describedby="modal-desc"
                    open={open}
                    onClose={() => setOpen(false)}
                    sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                    <Sheet
                        variant="outlined"
                        sx={{ width: '40%', borderRadius: 'md', p: 3, boxShadow: 'lg' }}
                    >
                        <Stack>
                            <Typography variant='h4'>Messages</Typography>
                            <ModalClose variant="plain" sx={{ m: 1 }} onClick={() => setOpen(false)} />
                        </Stack>
                        <div style={{ width: "100%", padding: "15px 0px 15px 0px", display: 'flex', justifyContent: 'flex-end' }}>
                            {/* <SearchBar data={data} setFilteredData={setfilterShowdata} filterKey={'USER_NAME'} /> */}
                            <Stack direction="row" spacing={2}>
                                <Pagination page={page1} setPage={setPage1} count={filtershowData?.length} rowsPerPage={rowsPerPage} />
                                {/* <Button sx={{ backgroundColor: '#009999', color: 'white' }} onClick={() => setOpen(true)} >ADD Agent</Button> */}
                            </Stack>
                        </div>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 600 }} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>from</StyledTableCell>
                                        <StyledTableCell>to</StyledTableCell>
                                        <StyledTableCell>type</StyledTableCell>
                                        <StyledTableCell>message</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {dataToShow2 && showData.map((row) => (
                                        <StyledTableRow key={row?._id}>
                                            <StyledTableCell component="th" scope="row">
                                                {row?.from}

                                            </StyledTableCell>
                                            <StyledTableCell component="th" scope="row">{row?.to}</StyledTableCell>
                                            <StyledTableCell component="th" scope="row">{row?.kind}</StyledTableCell>
                                            <StyledTableCell component="th" scope="row">
                                                {row?.kind === "image" ? (
                                                    <img
                                                        src={`https://pocwhatsapp.vibhohcm.com/uploads/${row?.message}`}
                                                        alt="message content"
                                                        style={{ width: "100px", height: "100px" }}
                                                    />
                                                ) : (
                                                    row?.message
                                                )}
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                    </Sheet>
                </Modal>

            </>
        </div>
    )
}

export default ChatHistory
