import React, { useEffect, useState } from 'react';
import './css/Dashboard.css';
import Card from '../components/Card';
import { useNavigate } from 'react-router-dom';
import instance from '../Utils/api';

const Dashboard = () => {
    const navigate = useNavigate();
    const [feedbackdata,setFeedbackData] = useState([]);
    const [authorsdata,setAuthorsData] = useState([]);
    const [templateList, setTemplateList] = useState([]);
    const [agents, setAgents] = useState([]);


    const fetchfeedbackData = async () => {
        try {
            const response = await instance.get("/getLeadsdata");
            setFeedbackData(response.data);
        } catch (error) {
            console.log(error);

        }
    }
    const fetchauthorsData = async () => {
        try {
            const response = await instance.get("/getuserdata");
            // console.log(response.data);
            setAuthorsData(response.data)
        } catch (error) {
            console.log(error);

        }
    }
    const fetchtemplatesData = async () => {
        try {
            const response = await instance.get("/templates");
            // console.log(response.data);
            setTemplateList(response.data.data)
        } catch (error) {
            console.log(error);

        }
    }
    const fetchAgentsData = async () => {
        try {
            const response = await instance.get("/getAgentsdata");
            console.log(response.data);
            setAgents(response.data)
        } catch (error) {
            console.log(error);

        }
    }
    useEffect(() => {
        fetchfeedbackData();
        fetchauthorsData();
        fetchtemplatesData();
        fetchAgentsData();
    }, [])
    return (
        <div className='dashboard'>
            <h1>Welcome to <b>FNB Connect.</b></h1>
            <div className='dashboard_cards' >
                <Card title={'Total Users'} count={authorsdata.length} onclick={() => { navigate('/UsersList') }} />
                <Card title={'Total Leads'} count={feedbackdata.length} onclick={() => { navigate('/LeadsList') }} />
                <Card title={'Total Templates'} count={templateList.length} onclick={() => { navigate('/Templates') }} />
                <Card title={'Total Agents'} count={agents.length} onclick={() => { navigate('/AgentsList') }} />
            </div>
        </div>
    )
}

export default Dashboard
