import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import instance from '../Utils/api';
import Pagination from '../components/Pagination';
import SearchBar from '../components/SearchBar';
import { Button, MenuItem, Modal, Select, Stack, TextField, Typography } from '@mui/material';
import { ModalClose, Sheet } from '@mui/joy';
import { toast } from 'react-toastify';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from 'react-router-dom';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#009999",
        color: theme.palette.common.white,
        fontSize: '16px',
        fontWeight: 600
        // fontFamily: "Poppins",

    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        // fontFamily: "Poppins",
        color: "#828282"
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,

    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const Agents_List = () => {
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [filteredData, setFilteredData] = useState(data);
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const [agent, setAgent] = useState({
        full_name: "",
        email: "",
        password: "",
        passcode: "68bd5290-1984-455d-88d5-bcb197643663"
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setAgent((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };



    const rowsPerPage = 10;
    const dataToShow = filteredData.slice((page - 1) * rowsPerPage, page * rowsPerPage);
    console.log(dataToShow);
    const fetchData = async () => {
        try {
            const response = await instance.get("/getAgentsdata");
            console.log(response);
            setData(response.data)
            setFilteredData(response.data)
        } catch (error) {
            console.log(error);

        }
    }

    const handleSubmit = async () => {
        if (agent.full_name === "" || agent.email === "" || agent.password === "" || agent.passcode === "") {
            return toast.error("Please fill all fields");
        }

        try {
            const response = await instance.post("/Agent/register", agent);
            console.log(response);
            if (response.data.success === true) {
                toast.success(response.data.message);
            } else {
                toast.error(response.data.message);
            }
            setOpen(false);
            setAgent({
                full_name: "",
                email: "",
                password: "",
            });

        } catch (error) {
            console.log(error);

        }
    }



    useEffect(() => {
        fetchData();
    }, [])
    return (
        <div>
            <div style={{ width: "100%", padding: "15px 0px 15px 0px", display: 'flex', justifyContent: 'space-between' }}>
                <SearchBar data={data} setFilteredData={setFilteredData} filterKey={'full_name'} />
                <Stack direction="row" spacing={2}>
                    <Pagination page={page} setPage={setPage} count={filteredData.length} rowsPerPage={rowsPerPage} />
                    <Button sx={{ backgroundColor: '#009999', color: 'white' }} onClick={() => setOpen(true)} >ADD Agent</Button>
                </Stack>
            </div>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 600 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Full Name</StyledTableCell>
                            <StyledTableCell>Email</StyledTableCell>
                            <StyledTableCell align="center">Available</StyledTableCell>
                            <StyledTableCell align="center">Client</StyledTableCell>
                            <StyledTableCell align="center">Chat History</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dataToShow.map((row) => (
                            <StyledTableRow key={row?.name}>
                                <StyledTableCell component="th" scope="row">
                                    {row?.full_name}

                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row">{row?.email}</StyledTableCell>
                                <StyledTableCell align="center">{row?.available === true ? "Active" : "InActive"}</StyledTableCell>
                                <StyledTableCell align="center">{row?.client}</StyledTableCell>
                                <StyledTableCell align="center" ><VisibilityIcon sx={{ cursor: 'pointer' }} onClick={() => { navigate(`/AgentsList/${row?._id}`) }} /></StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <>
                <Modal
                    aria-labelledby="modal-title"
                    aria-describedby="modal-desc"
                    open={open}
                    onClose={() => setOpen(false)}
                    sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                    <Sheet
                        variant="outlined"
                        sx={{ width: '35%', borderRadius: 'md', p: 3, boxShadow: 'lg' }}
                    >
                        <ModalClose variant="plain" sx={{ m: 1 }} onClick={() => setOpen(false)} />
                        <Typography
                            component="h2"
                            id="modal-title"
                            level="h4"
                            textColor="inherit"
                            variant="h5"
                            sx={{ fontWeight: 'lg', mb: 2.5 }}
                        >
                            Add Agent
                        </Typography>
                        <Stack direction="column" spacing={2} sx={{ mt: 2.5 }}>
                            <TextField
                                id="outlined-basic"
                                label="Full Name"
                                variant="outlined"
                                name="full_name"
                                type='text'
                                value={agent.full_name}
                                onChange={handleChange}
                                required
                            />
                            <TextField
                                id="outlined-basic"
                                label="Email"
                                variant="outlined"
                                name="email"
                                type='email'
                                value={agent.email}
                                onChange={handleChange}
                                required
                            />
                            <TextField
                                id="outlined-basic"
                                label="Password"
                                variant="outlined"
                                name="password"
                                type="password"
                                value={agent.password}
                                onChange={handleChange}
                                required
                            />
                            <Button
                                sx={{
                                    backgroundColor: '#009999',
                                    color: 'white',
                                    padding: '10px 28px',
                                    minWidth: '48%'
                                }}
                                onClick={handleSubmit}
                            >
                                Submit
                            </Button>
                        </Stack>
                    </Sheet>
                </Modal>

            </>
        </div>
    )
}

export default Agents_List
