import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import instance from '../Utils/api';
import Pagination from '../components/Pagination';
import SearchBar from '../components/SearchBar';
import { Button, Modal, Stack, TextField, Typography } from '@mui/material';
import ModalClose from '@mui/joy/ModalClose';
import Sheet from '@mui/joy/Sheet';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#009999",
        color: theme.palette.common.white,
        fontFamily: "Poppins",

    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        fontFamily: "Poppins",
        color: "#828282"
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const Messages = () => {
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [filteredData, setFilteredData] = useState(data);
    const [open, setOpen] = useState(false);
    const [template, setTemplate] = useState('');
    const [templateList, setTemplateList] = useState([]);
    const [file, setFile] = useState('');
    console.log(file);

    const handleChange = (event) => {
        setTemplate(event.target.value);
    };

    const rowsPerPage = 10;
    const dataToShow = filteredData.slice((page - 1) * rowsPerPage, page * rowsPerPage);
    console.log(dataToShow);
    const fetchData = async () => {
        try {
            const response = await instance.get("/getbulkmessages");
            console.log(response.data);
            setData(response.data)
            setFilteredData(response.data)
        } catch (error) {
            console.log(error);

        }
    }
    const fetchtemplates = async () => {
        try {
            const response = await instance.get("/templates");
            // console.log(response.data.data);
            setTemplateList(response.data.data)
        } catch (error) {
            console.log(error);

        }
    }

    const postData = async () => {
        try {
            const formData = new FormData();
            formData.append('Numbers', file);
            formData.append('TemplateName', template.name);

            const response = await instance.post("/sendBulkMessage", formData);
            console.log(response);
            if (response.data.success === true) {
                toast.success(response.data.message);
                setOpen(false);
                setTemplate('');
                fetchData();
            } else {
                toast.success(response.data.message);
            }
        } catch (error) {
            console.log(error);
            toast.success(error.response.data.message);
        }
    }

    const downloadExcel = () => {
        // Create a new workbook
        const workbook = XLSX.utils.book_new();

        // Create data for the Excel sheet
        const data = [
            { PHONE_NUMBER: '1234567890' }, // Add your phone numbers here
            { PHONE_NUMBER: '0987654321' }, // Sample data
            // Add more rows as needed
        ];

        // Convert the data to a worksheet
        const worksheet = XLSX.utils.json_to_sheet(data);

        // Append the worksheet to the workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

        // Generate a binary string for the workbook
        const excelBuffer = XLSX.write(workbook, {
            bookType: 'xlsx',
            type: 'array',
        });

        // Create a blob from the binary string
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });

        // Trigger download
        saveAs(blob, 'phone_numbers.xlsx');
    };

    useEffect(() => {
        fetchData();
        fetchtemplates();
    }, [])
    console.log(templateList);

    return (
        <div>
            <div style={{ width: "100%", padding: "15px 0px 15px 0px", display: 'flex', justifyContent: 'space-between' }}>
                <SearchBar data={data} setFilteredData={setFilteredData} filterKey={'PHONE_NUMBER'} />
                <Stack direction="row" spacing={2}>
                    <Pagination page={page} setPage={setPage} count={filteredData.length} rowsPerPage={rowsPerPage} />
                    <Button sx={{ backgroundColor: '#009999', color: 'white' }} onClick={() => setOpen(true)}>Send Bulk Messages</Button>
                </Stack>
            </div>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 600 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Phone Number</StyledTableCell>
                            <StyledTableCell>Template Name</StyledTableCell>
                            <StyledTableCell>Excel file</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dataToShow.map((row) => (
                            <StyledTableRow key={row._id}>
                                <StyledTableCell>{row.PHONE_NUMBER}</StyledTableCell>
                                <StyledTableCell>{row.TEMPLATE_NAME}</StyledTableCell>
                                <StyledTableCell>{row.EXCEL_FILE_NAME}</StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <>
                <Modal
                    aria-labelledby="modal-title"
                    aria-describedby="modal-desc"
                    open={open}
                    onClose={() => setOpen(false)}
                    sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                    <Sheet
                        variant="outlined"
                        sx={{ maxWidth: 800, borderRadius: 'md', p: 3, boxShadow: 'lg' }}
                    >
                        <ModalClose variant="plain" sx={{ m: 1 }} onClick={() => setOpen(false)} />
                        <Typography
                            component="h2"
                            id="modal-title"
                            level="h4"
                            textColor="inherit"
                            variant='h5'
                            sx={{ fontWeight: 'lg', mb: 2.5 }}
                        >
                            Bulk Messages
                        </Typography>
                        <Stack direction="column" spacing={2}>
                            <FormControl fullWidth>
                                <InputLabel
                                    id="demo-simple-select-label"
                                    sx={{
                                        '&.Mui-focused': {
                                            color: '#009999', // Changes label color when focused
                                        },
                                    }}
                                >
                                    Template Names
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={template}
                                    label="Template Names"
                                    onChange={handleChange}
                                    sx={{
                                        '& .Mui-focused': {
                                            color: '#009999', // Changes label color when focused
                                        },
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#009999', // Changes the border color when clicked
                                        },
                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#009999', // Changes border color on hover
                                        },
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#009999', // Changes border color when focused
                                        },
                                        '& .MuiMenuItem-root.Mui-selected': {
                                            backgroundColor: '#009999', // Changes selected item background color
                                        },
                                    }}
                                >
                                    {templateList.map((row) => (
                                        !row.components.some(component =>
                                            component.example || (component.buttons && component.buttons[0]?.type === "FLOW")
                                        ) ? ( // Exclude components with `example` or of type `FLOW`
                                            <MenuItem key={row.name} value={row}>
                                                {row.name}
                                            </MenuItem>
                                        ) : null // Return null if any component has `example` or is a `FLOW` type
                                    ))}


                                </Select>
                            </FormControl>

                            <Stack sx={{ border: '2px solid #009999', padding: '10px', borderRadius: '6px' }}>
                                <input type='file' onChange={(e) => { setFile(e.target.files[0]) }} />
                            </Stack>
                            <Typography variant='body1' sx={{ fontSize: '15px' }} >*Please Add countrycode + Phone Number like  27123456789</Typography>

                            {/* <TextField id="outlined-basic" label="Mobile Number" variant="outlined" onChange={(e) => { setNumber(e.target.value) }} /> */}

                            <Stack direction="row" spacing={2}>
                                <Button sx={{ backgroundColor: '#009999', color: 'white', padding: '0px 28px', minWidth: '48%' }} onClick={postData} >Send</Button>
                                <Button sx={{ backgroundColor: '#009999', color: 'white', minWidth: '48%', marginLeft: '0px' }} onClick={downloadExcel}>Sample Document</Button>
                            </Stack>
                        </Stack>
                    </Sheet>
                </Modal>
            </>
        </div>
    )
}

export default Messages
