import './styles/SearchBar.css';
import search from '../Assets/Search.svg';

export default function SearchBar({ data, filterKey, setFilteredData }) {

    const getNestedValue = (obj, key) => {
        return key.split('.').reduce((acc, part) => acc && acc[part], obj);
    };

    function changeHandler(e) {
        const searchValue = e.target.value.toLowerCase();
        setFilteredData(data.filter(item => {
            const itemValue = getNestedValue(item, filterKey);
            return itemValue && itemValue.toString().toLowerCase().includes(searchValue);
        }));
    }

    return (
        <div className="_4tet">
            <img src={search} alt="search" className="sappier-seas" />
            <input type="text" placeholder="Search" className="refold-pat" onChange={changeHandler} />
        </div>
    );
}
