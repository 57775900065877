// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard h1 {
    margin: 0px;
    font-family: "Poppins";
    color: #828282;
    font-size: 30px;
}

.dashboard_cards {
    height: 30vh;
    display: flex;
    align-content: center;
    margin-top: 20px;
    /* flex-wrap: wrap; */
    /* justify-content: space-around; */
    gap: 20px;
}

.textarea {
    width: 98%;
    border: 2px solid #009999;
    border-radius: 6px;
}
/* #demo-simple-select{
    border: 2px solid #009999;

} */`, "",{"version":3,"sources":["webpack://./src/pages/css/Dashboard.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,sBAAsB;IACtB,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,qBAAqB;IACrB,gBAAgB;IAChB,qBAAqB;IACrB,mCAAmC;IACnC,SAAS;AACb;;AAEA;IACI,UAAU;IACV,yBAAyB;IACzB,kBAAkB;AACtB;AACA;;;GAGG","sourcesContent":[".dashboard h1 {\n    margin: 0px;\n    font-family: \"Poppins\";\n    color: #828282;\n    font-size: 30px;\n}\n\n.dashboard_cards {\n    height: 30vh;\n    display: flex;\n    align-content: center;\n    margin-top: 20px;\n    /* flex-wrap: wrap; */\n    /* justify-content: space-around; */\n    gap: 20px;\n}\n\n.textarea {\n    width: 98%;\n    border: 2px solid #009999;\n    border-radius: 6px;\n}\n/* #demo-simple-select{\n    border: 2px solid #009999;\n\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
